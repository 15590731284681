/*
 * Copyright 2024 Harness Inc. All rights reserved.
 * Use of this source code is governed by the PolyForm Shield 1.0.0 license
 * that can be found in the licenses directory at the root of this repository, also available at
 * https://polyformproject.org/wp-content/uploads/2020/06/PolyForm-Shield-1.0.0.txt.
 */

import { UserInfoFromClearBitDTO } from "services/ng";

export const phoneNumberWithoutCountryCodeRegex = /^(?:[0-9\-\\(\\)\\/.]\s?){5,14}[0-9]{1}$/;

export const allRichUserInfoFields: (keyof Omit<
  UserInfoFromClearBitDTO,
  "userEmail"
>)[] = [
  "companyName",
  "firstName",
  "lastName",
  "jobTitle",
  "phoneNumber",
  "state",
  "country"
];

export const fieldNameLabelMapping: { [key: string]: string } = {
  companyName: "Company Name",
  firstName: "First Name",
  lastName: "Last Name",
  jobTitle: "Job Title",
  phoneNumber: "Phone Number",
  state: "State",
  country: "Country"
};
