/*
 * Copyright 2021 Harness Inc. All rights reserved.
 * Use of this source code is governed by the PolyForm Shield 1.0.0 license
 * that can be found in the licenses directory at the root of this repository, also available at
 * https://polyformproject.org/wp-content/uploads/2020/06/PolyForm-Shield-1.0.0.txt.
 */

import React, { useEffect } from "react";
import cx from "classnames";
import logo from "static/images/harness-logo.svg";
import Spinner from "static/icons/spinner/Spinner";
import { useParams } from "react-router-dom";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import { EMAIL_VERIFY_STATUS } from "utils/StringUtils";
import {
  EXPERIMENTS,
  getGaClientID,
  getSavedRefererURL,
  handleSignUpSuccess
} from "utils/SignUpUtils";
import { handleError } from "utils/ErrorUtils";
import Text from "components/Text/Text";
import {
  useCompleteSignupInvite,
  useResendVerifyEmail,
  CompleteSignupInvitePathParams,
  UserInfo
} from "services/ng";
import toast from "react-hot-toast";
import BasicLayoutExperimental from "pages/SignUp/SaasExperimentalForms/BasicLayout/BasicLayoutExperimental";
import { FF_MAP } from "utils/TelemetryUtils";
import WithFFProvider from "components/WithFFProvider/WithFFProvider";
import basicLayoutExperimentalCSS from "../SignUp/SaasExperimentalForms/BasicLayout/BasicLayoutExperimental.module.css";
import css from "./CompleteInvitePage.module.css";

const harnessLogo = (
  <img
    src={logo}
    width={120}
    className={css.marginBottomHuge}
    alt={"Harness"}
  />
);

interface ResendButtonProps {
  email: string;
}

interface EmailVerificationFailedProps {
  email: string | null;
}

const ResendButton = (props: ResendButtonProps): React.ReactElement => {
  const { email } = props;

  const { mutate: resendVerifyEmail, loading, error } = useResendVerifyEmail({
    queryParams: { email }
  });

  async function handleResendVerifyEmail() {
    await resendVerifyEmail();

    toast("Successfully resent the verification email");
  }

  if (error) {
    handleError(error);
  }

  return (
    <button
      disabled={loading}
      className={cx("button", "primary", css.resendButton)}
      onClick={handleResendVerifyEmail}
    >
      <Text>Resend verification email</Text>
    </button>
  );
};

const VerifyInProgress = (): React.ReactElement => {
  return (
    <div>
      {harnessLogo}
      <Spinner className={cx(css.spinner, css.marginBottomLarge)} />
      <Text className={css.title}>{EMAIL_VERIFY_STATUS.IN_PROGRESS}</Text>
    </div>
  );
};

const VerifySuccess = (): React.ReactElement => {
  return (
    <div>
      {harnessLogo}
      <Text className={css.title}>{EMAIL_VERIFY_STATUS.SUCCESS}</Text>
    </div>
  );
};

const VerifyFailed = (
  props: EmailVerificationFailedProps
): React.ReactElement => {
  const { email } = props;

  const resendButton = email && <ResendButton email={email} />;

  return (
    <div>
      {harnessLogo}

      <Text
        icon="warningSign"
        iconProps={{ size: 28 }}
        className={cx(css.title, css.marginBottomLarge)}
      >
        {EMAIL_VERIFY_STATUS.FAILED}
      </Text>

      <Text className={cx(css.marginBottomLarge, css.lineHeight1dot5)}>
        Your Email verification wasn’t successful. Please verify the Email
        again.
      </Text>
      {resendButton}
    </div>
  );
};

const CompleteInvitePageNoFF = (): React.ReactElement => {
  const { token } = useParams<CompleteSignupInvitePathParams>();

  const PL_NEW_USERINFO_PAGE_ENABLED = useFeatureFlag(
    FF_MAP.PL_NEW_USERINFO_PAGE_ENABLED
  );

  const refererURL = getSavedRefererURL();
  const gaClientId = getGaClientID();
  const {
    mutate: completeSignupInvite,
    loading,
    error
  } = useCompleteSignupInvite({
    token: token,
    requestOptions: { headers: { "content-type": "application/json" } },
    queryParams: {
      ...(refererURL ? { referer: refererURL } : {}),
      ...(gaClientId ? { gaClientId } : {})
    }
  });

  function getEmailFromUrlSearchParam(): string | null {
    const queryString = window.location.hash?.split("?")?.[1];
    const urlParams = new URLSearchParams(queryString);
    return urlParams?.get("email");
  }

  async function handleCompleteSignup(): Promise<void> {
    try {
      const response = await completeSignupInvite();
      const userInfo: UserInfo | undefined = response?.resource;

      if (userInfo) {
        handleSignUpSuccess(userInfo, PL_NEW_USERINFO_PAGE_ENABLED);
      } else {
        handleError("Something went wrong. Please try again.");
      }
    } catch (error) {
      handleError(error);
    }
  }

  useEffect(() => {
    handleCompleteSignup();
  }, []);

  function getInnerComponent() {
    if (loading) {
      return <VerifyInProgress />;
    } else if (error) {
      return <VerifyFailed email={getEmailFromUrlSearchParam()} />;
    } else {
      return <VerifySuccess />;
    }
  }

  return (
    <BasicLayoutExperimental>
      {
        <div className={basicLayoutExperimentalCSS.card}>
          {getInnerComponent()}
        </div>
      }
    </BasicLayoutExperimental>
  );
};

const CompleteInvitePage = (): JSX.Element => (
  <WithFFProvider
    featureFlagsToken={window.featureFlagsToken}
    config={{ experiment: [EXPERIMENTS.SIGNUP_PAGE] }}
    fallback={<CompleteInvitePageNoFF />}
  >
    <CompleteInvitePageNoFF />
  </WithFFProvider>
);

export default CompleteInvitePage;
