/*
 * Copyright 2022 Harness Inc. All rights reserved.
 * Use of this source code is governed by the PolyForm Shield 1.0.0 license
 * that can be found in the licenses directory at the root of this repository, also available at
 * https://polyformproject.org/wp-content/uploads/2020/06/PolyForm-Shield-1.0.0.txt.
 */

import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import { Link, useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import { useSignup, SignupDTO, useMarketplaceSignup } from "services/ng";
import RouteDefinitions from "RouteDefinitions";
import { handleError } from "utils/ErrorUtils";
import telemetry from "telemetry/Telemetry";
import { useQueryParams } from "hooks/useQueryParams";
import WithFFProvider from "components/WithFFProvider/WithFFProvider";
import { VERIFY_EMAIL_STATUS } from "pages/VerifyEmail/VerifyEmailStatus";
import {
  BillingFrequency,
  Edition,
  EXPERIMENTS,
  getCookieByName,
  getSavedRefererURL,
  SignupAction
} from "utils/SignUpUtils";
import { CATEGORY, PAGE, EVENT, FF_MAP } from "utils/TelemetryUtils";
import SignupFormWithCredentials from "./SignupFormWithCredentials";
import SignupFormOAuth from "./SignupFormOAuth";
import BasicLayoutExperimental from "./BasicLayout/BasicLayoutExperimental";
import { getModuleDetails, isRestrictedEmail, updateReferer } from "./utils";
import { URLS } from "interfaces/OAuthProviders";
import basicLayoutExperimentalCSS from "./BasicLayout/BasicLayoutExperimental.module.css";
import css from "../SignUp.module.css";

export interface SignUpFormData {
  email: string;
  password: string;
  name?: string;
  companyName?: string;
}
export enum SIGNUPFORM_TYPES {
  OAUTH_FORM = "OAUTH_FORM",
  CREDENTIAL_FORM = "CREDENTIAL_FORM"
}
const SignUpExperimental: React.FC = () => {
  const [formType, setFormType] = useState<SIGNUPFORM_TYPES>(
    SIGNUPFORM_TYPES.OAUTH_FORM
  );
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);

  const [hasRestrictedEmail, setRestrictedEmail] = useState(false);
  const history = useHistory();

  const { mutate: signup, loading } = useSignup({});
  const {
    mutate: marketplaceSignup,
    loading: marketplaceLoading
  } = useMarketplaceSignup({});
  const captchaRef = useRef<ReCAPTCHA>(null);
  const {
    module = "",
    signupAction,
    edition,
    billingFrequency,
    utm_source,
    utm_content,
    utm_medium,
    utm_term,
    utm_campaign,
    marketPlaceToken,
    inviteId
  } = useQueryParams<{
    module?: string;
    signupAction?: string;
    edition?: string;
    billingFrequency?: string;
    utm_source?: string;
    utm_content?: string;
    utm_medium?: string;
    utm_term?: string;
    utm_campaign?: string;
    marketPlaceToken?: string;
    inviteId?: string;
  }>();

  const isMarketPlaceSignup = Boolean(marketPlaceToken) && false;
  const utmCampaign = utm_campaign || getCookieByName("utm_campaign") || "";
  const utmSource = utm_source || getCookieByName("utm_source") || "";
  const utmContent = utm_content || getCookieByName("utm_content") || "";
  const utmMedium = utm_medium || getCookieByName("utm_medium") || "";
  const utmTerm = utm_term || getCookieByName("utm_term") || "";
  const moduleDetails = getModuleDetails(module as string);
  const [captchaExecuting, setCaptchaExecuting] = useState(false);
  const utmProps = {
    utm_source: utmSource,
    utm_content: utmContent,
    utm_medium: utmMedium,
    utm_term: utmTerm,
    utm_campaign: utmCampaign
  };
  const handleSignup = async (
    data: SignUpFormData,
    captchaToken: string
  ): Promise<void> => {
    const encodedEmail = encodeURIComponent(data.email);
    const { email, password, companyName, name } = data;
    try {
      const signupRequestData: SignupDTO = {
        email,
        password,
        ...(isMarketPlaceSignup
          ? { companyName, name, marketPlaceToken, inviteId }
          : {}),
        intent: module,
        utmInfo: {
          utmSource,
          utmContent,
          utmMedium,
          utmTerm,
          utmCampaign
        }
      };

      if (module === "") {
        delete signupRequestData.intent;
      }

      if (signupAction && signupAction.toUpperCase() in SignupAction) {
        signupRequestData.signupAction = signupAction.toUpperCase() as SignupAction;
      }

      if (edition && edition.toUpperCase() in Edition) {
        signupRequestData.edition = edition.toUpperCase() as Edition;
      }

      if (
        billingFrequency &&
        billingFrequency.toUpperCase() in BillingFrequency
      ) {
        signupRequestData.billingFrequency = billingFrequency.toUpperCase() as BillingFrequency;
      }
      if (isMarketPlaceSignup) {
        await marketplaceSignup(signupRequestData, {
          queryParams: { inviteId, marketPlaceToken }
        });
        history.push({
          pathname: RouteDefinitions.toSignIn()
        });
      } else {
        await signup(signupRequestData, {
          queryParams: { captchaToken: captchaToken }
        });

        history.push({
          pathname: RouteDefinitions.toEmailVerification(),
          search: `?status=${VERIFY_EMAIL_STATUS.EMAIL_SENT}&email=${encodedEmail}&module=${module}`
        });
      }
    } catch (error) {
      captchaRef.current?.reset?.();

      if (
        error?.data?.responseMessages?.length &&
        error?.data?.responseMessages[0]?.code === "USER_ALREADY_REGISTERED"
      ) {
        history.push({
          pathname: RouteDefinitions.toEmailVerification(),
          search: `?status=${VERIFY_EMAIL_STATUS.SIGNED_UP}&email=${encodedEmail}&module=${module}`
        });
      } else {
        handleError(error);
      }
    }
  };

  const onSubmit = (data: SignUpFormData) => {
    if (isRestrictedEmail(data.email)) {
      setRestrictedEmail(true);
      telemetry.track({
        event: EVENT.SIGNUP_FREE_DOMAIN_ERROR,
        properties: {
          intent: module,
          category: CATEGORY.SIGNUP,
          userId: data.email,
          ...utmProps
        }
      });
      return;
    } else if (hasRestrictedEmail) {
      setRestrictedEmail(false);
    }

    if (captchaToken) {
      data.email = data.email.toLowerCase();

      handleSignup(data, captchaToken);
      telemetry.track({
        event: EVENT.SIGNUP_SUBMIT,
        properties: {
          intent: module,
          category: CATEGORY.SIGNUP,
          userId: data.email,
          ...utmProps
        }
      });
    }
  };

  useEffect(() => {
    updateReferer();
    const refererURL = getSavedRefererURL();
    telemetry.page({
      name: PAGE.SIGNUP_PAGE,
      properties: {
        intent: module,
        ...utmProps,
        ...(refererURL ? { refererURL } : {})
      }
    });
  }, []);

  function handleRecaptchaError() {
    // Block the user until they refresh
    setCaptchaExecuting(true);
    handleError("Captcha has failed, please refresh the page.");
  }

  return (
    <BasicLayoutExperimental>
      <div className={basicLayoutExperimentalCSS.card}>
        <div className={cx(css.signup, css.experimentSignup)}>
          {/* <div className={css.header}>
          <img src={logo} width={120} className={css.logo} />
        </div> */}
          <div className={css.title}>Sign up</div>
          <div
            className={cx(css.subtitle, {
              [css.subtitleEmailForm]:
                formType === SIGNUPFORM_TYPES.CREDENTIAL_FORM
            })}
          >
            Get started for free. No credit card required.
          </div>
          {formType === SIGNUPFORM_TYPES.OAUTH_FORM &&
          window.oauthSignupEnabled === "true" &&
          !marketPlaceToken ? (
            <SignupFormOAuth
              changeFormType={() =>
                setFormType(SIGNUPFORM_TYPES.CREDENTIAL_FORM)
              }
            />
          ) : (
            <SignupFormWithCredentials
              isMarketPlaceSignup={isMarketPlaceSignup}
              onSubmit={onSubmit}
              loading={loading || marketplaceLoading}
              captchaExecuting={captchaExecuting}
              setCaptchaToken={setCaptchaToken}
              captchaToken={captchaToken}
              handleRecaptchaError={handleRecaptchaError}
              captchaRef={captchaRef}
              hasRestrictedEmail={hasRestrictedEmail}
            />
          )}
        </div>
        <div className={css.footer}>
          Already have an account?
          <p className={css.signintext}>
            <Link to={RouteDefinitions.toSignIn()}>Sign in</Link>
          </p>
        </div>
        <p className={css.agreement}>
          By signing up, you agree to our
          <a
            href={URLS.PRIVACY_AGREEMENT}
            className={css.link}
            rel="noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>
          and our
          <a
            href={URLS.SUBSCRIPTION_TERMS}
            className={css.link}
            rel="noreferrer"
            target="_blank"
          >
            Terms of Use
          </a>
        </p>
        <img src={moduleDetails.pathImg} className={css.imagecd} />
      </div>
    </BasicLayoutExperimental>
  );
};
const SignupEnhanced = (): JSX.Element => (
  <WithFFProvider
    featureFlagsToken={window.featureFlagsToken}
    config={{ experiment: [EXPERIMENTS.EMAIL_RESTRICTION_AB] }}
    fallback={<SignUpExperimental />}
  >
    <SignUpExperimental />
  </WithFFProvider>
);
export default SignupEnhanced;
