/*
 * Copyright 2024 Harness Inc. All rights reserved.
 * Use of this source code is governed by the PolyForm Shield 1.0.0 license
 * that can be found in the licenses directory at the root of this repository, also available at
 * https://polyformproject.org/wp-content/uploads/2020/06/PolyForm-Shield-1.0.0.txt.
 */

import React, { useState } from "react";

import BasicLayoutExperimental from "pages/SignUp/SaasExperimentalForms/BasicLayout/BasicLayoutExperimental";

import css from "./AccountBlocked.module.css";
import { Link, useHistory } from "react-router-dom";
import RouteDefinitions from "RouteDefinitions";
import moduleFg from "./module-fg.png";

export const AccountBlocked = (): React.ReactElement => {
  const [email, setEmail] = useState<string | null>(null);
  const [accountId, setAccountId] = useState<string | null>(null);
  const history = useHistory();

  React.useEffect(() => {
    const sessionEmail = sessionStorage.getItem("email");
    const sessionAccountId = sessionStorage.getItem("accountId");

    if (sessionAccountId) {
      setAccountId(sessionAccountId);
    } else if (sessionEmail) {
      setEmail(sessionEmail);
    } else {
      // redirect to sign in page because user landed on account blocked page by directly entering the url in the browser
      history.push(RouteDefinitions.toSignIn());
    }
    return () => {
      sessionStorage.removeItem("accountId");
      sessionStorage.removeItem("email");
    };
  }, []);

  return (
    <>
      <BasicLayoutExperimental>
        <div className={css.accountBlockedContainer}>
          <div className={css.title}>Your account is blocked.</div>
          <div className={css.subTitle}>
            This account linked with the{" "}
            {email ? <>email id {email} </> : <> account id {accountId}</>} has
            been inactive for more than 90 days and is marked for deletion. If
            you would like to use this account again, please contact Harness
            Support.{" "}
          </div>
          <div className={css.layoutHorizontal}>
            <a href="mailto:support@harness.io">
              <button className={css.buttonCss}>Contact Harness Support</button>
            </a>
            <Link
              to={RouteDefinitions.toSignIn()}
              className={css.anotherAccount}
            >
              <b>Login to another account</b>
            </Link>
          </div>
        </div>
        <img src={moduleFg} alt="" aria-hidden className={css.moduleImageCss} />
      </BasicLayoutExperimental>
    </>
  );
};
