/*
 * Copyright 2021 Harness Inc. All rights reserved.
 * Use of this source code is governed by the PolyForm Shield 1.0.0 license
 * that can be found in the licenses directory at the root of this repository, also available at
 * https://polyformproject.org/wp-content/uploads/2020/06/PolyForm-Shield-1.0.0.txt.
 */

import toast from "react-hot-toast";

export function handleError(error: any): void {
  const response = error?.data;
  if (
    response?.responseMessages?.length > 0 &&
    response?.responseMessages[response.responseMessages.length - 1].message
  ) {
    toast.error(
      response.responseMessages[response.responseMessages.length - 1].message ||
        "",
      { duration: 10000 }
    );
  } else if (error.message) {
    if (![429, 401].includes(error.status)) {
      toast(error.message, { duration: 10000 });
    }
  } else if (typeof error === "string") {
    toast(error, { duration: 10000 });
  }

  if (error instanceof Error) {
    window.Bugsnag?.notify?.(error);
  }
}

// Enum for ErrorCode that are handled by ng-auth-ui in sync with harness-core-ui
export enum ErrorCode {
  GATEWAY_SSO_REDIRECT_ERROR = "GATEWAY_SSO_REDIRECT_ERROR",
  NOT_WHITELISTED_IP = "NOT_WHITELISTED_IP",
  UNAUTHORIZED = "UNAUTHORIZED",
  invalidsso = "invalidsso",
  email_verify_fail = "email_verify_fail",
  INVITE_EXPIRED = "INVITE_EXPIRED",
  INVITE_INVALID = "INVITE_INVALID",
  DOMAIN_WHITELIST_FAILED = "DOMAIN_WHITELIST_FAILED"
}
