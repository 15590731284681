/*
 * Copyright 2024 Harness Inc. All rights reserved.
 * Use of this source code is governed by the PolyForm Shield 1.0.0 license
 * that can be found in the licenses directory at the root of this repository, also available at
 * https://polyformproject.org/wp-content/uploads/2020/06/PolyForm-Shield-1.0.0.txt.
 */

import React from "react";
import cx from "classnames";

import { Field as FinalField } from "react-final-form";
import css from "./Field.module.css";

interface SelectDropdownFieldProps {
  name: string;
  label?: string;
  className?: string;
  validate?: () => string | undefined;
  onChange?: (val: string) => void;
  placeholder?: string;
  initialValue?: string;
  options: Array<{ value: string; label: string }>;
  id: string;
}

const SelectDropdownField: React.FC<SelectDropdownFieldProps> = (props) => {
  const {
    id,
    options,
    name,
    validate,
    onChange,
    initialValue,
    className,
    label = "Select an option:"
  } = props;
  return (
    <FinalField name={name} validate={validate} initialValue={initialValue}>
      {({ input, meta }) => {
        const showError = meta.error && meta.touched;

        return (
          <div className={cx("layout-vertical spacing-small", css.dropdown)}>
            <label htmlFor={id}>{label}</label>
            <select
              {...input}
              className={cx(
                css.selectDropdown,
                showError && css["validation-outline"],
                className
              )}
              id={id}
              data-testid={id}
              onChange={(e) => {
                const val = e?.target?.value;
                input.onChange(val);
                onChange?.(val);
              }}
            >
              <option>-- Select --</option>
              {options?.map((option, index) => {
                return (
                  <option key={`${option.value}_${index}`} value={option.value}>
                    {option.label}
                  </option>
                );
              })}
            </select>
            {showError && (
              <span className={cx(css["validation-message"])}>
                {meta.error}
              </span>
            )}
          </div>
        );
      }}
    </FinalField>
  );
};

export default SelectDropdownField;
