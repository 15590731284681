/*
 * Copyright 2021 Harness Inc. All rights reserved.
 * Use of this source code is governed by the PolyForm Shield 1.0.0 license
 * that can be found in the licenses directory at the root of this repository, also available at
 * https://polyformproject.org/wp-content/uploads/2020/06/PolyForm-Shield-1.0.0.txt.
 */

export const regexEmail = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const regexUsernameDisallowedChars = /^[^/:<>=()]+$/;

export enum EMAIL_VERIFY_STATUS {
  SUCCESS = "Email verified successfully!",
  IN_PROGRESS = "Verifying email address",
  FAILED = "Cannot verify email",
  EMAIL_SENT = "Verify email address",
  ALREADY_SIGNED_UP = "Already signed up"
}

// Source: https://stackoverflow.com/a/30106551
// Encoding UTF-8 -> base64
export const b64EncodeUnicode = (str: string): string => {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode(parseInt(p1, 16));
    })
  );
};

// Decoding base64 -> UTF-8
export const b64DecodeUnicode = (str: string): string => {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
};
