/*
 * Copyright 2021 Harness Inc. All rights reserved.
 * Use of this source code is governed by the PolyForm Free Trial 1.0.0 license
 * that can be found in the licenses directory at the root of this repository, also available at
 * https://polyformproject.org/wp-content/uploads/2020/05/PolyForm-Free-Trial-1.0.0.txt.
 */

import React, { useState } from "react";
import cx from "classnames";
import toast from "react-hot-toast";
import { Form } from "react-final-form";
import { Link } from "react-router-dom";

import { SSORequest, useGetLoginTypeV2 } from "services/portal";
import RouteDefinitions from "RouteDefinitions";
import BasicLayout from "components/BasicLayout/BasicLayout";
import Field from "components/Field/Field";
import Text from "components/Text/Text";
import telemetry from "telemetry/Telemetry";

import logo from "static/images/harness-logo.svg";
import css from "../SignIn/SignIn.module.css";
import { validateEmail } from "utils/FormValidationUtils";
import { getAccountIdFromUrl } from "utils/LoginUtils";
import Icon, { IconName } from "components/Icon/Icon";

interface SSOFormData {
  email: string;
}

export enum Providers {
  AZURE = "AZURE",
  OKTA = "OKTA",
  ONE_LOGIN = "ONELOGIN",
  OTHER = "OTHER"
}

const samlIconMap: Record<Providers, IconName> = {
  [Providers.AZURE]: "service-azure",
  [Providers.OKTA]: "service-okta",
  [Providers.ONE_LOGIN]: "service-onelogin",
  [Providers.OTHER]: "service-other"
};

const SSOSignIn: React.FC = () => {
  const ssoRequests = sessionStorage.getItem("ssoRequests");
  const [multipleSSO, setMultipleSSO] = useState<SSORequest[]>(
    ssoRequests ? JSON.parse(ssoRequests) : []
  );
  const returnUrl = sessionStorage.getItem("returnUrl");
  const accountId = returnUrl ? getAccountIdFromUrl(returnUrl) : undefined;

  const { mutate: getLoginType, loading } = useGetLoginTypeV2({
    queryParams: { accountId }
  });

  const handleSSOInit = async (data: SSOFormData): Promise<void> => {
    try {
      const res = await getLoginType({ userName: data.email });
      if (res.resource?.authenticationMechanism === "SAML") {
        if (
          res?.resource?.ssoRequests &&
          res?.resource?.ssoRequests.length > 0
        ) {
          if (res?.resource?.ssoRequests.length === 1) {
            if (res.resource.ssoRequests[0]?.idpRedirectUrl) {
              // send identify user event to telemetry to update the identity
              telemetry.identify({ userId: data.email });
              window.location.href =
                res.resource.ssoRequests[0]?.idpRedirectUrl;
            } else {
              toast("Single sign-on is not enabled for your account");
            }
          } else {
            setMultipleSSO(res.resource.ssoRequests);
          }
        }
      } else {
        toast("Single sign-on is not enabled for your account");
      }
    } catch (err) {
      toast("There was an error");
    }
  };

  const renderSSOCard = (ssoRequest: SSORequest) => {
    return (
      <div
        key={ssoRequest.ssoId}
        className={css.ssoCard}
        onClick={() => {
          if (ssoRequest.idpRedirectUrl) {
            window.location.href = ssoRequest.idpRedirectUrl;
          } else {
            toast("Single sign-on is not enabled for your account");
          }
        }}
      >
        <Icon
          size={25}
          name={samlIconMap[ssoRequest.samlProviderType as Providers]}
        />
        <div className={css.ssoTextContainer}>
          <Text className={css.ssoCardText}>{ssoRequest.friendlySamlName}</Text>
        </div>
      </div>
    );
  };

  return (
    <BasicLayout>
      <div className={cx(css.signin)}>
        <div className={css.header}>
          <img src={logo} width={120} className={css.logo} alt={"Harness"} />
          <div style={{ flex: 1 }} />
          {!ssoRequests ? (
            <Link to={RouteDefinitions.toSignIn()}>
              <Text icon="leftArrow">Main sign in</Text>
            </Link>
          ) : undefined}
        </div>
        <div className={css.title}>
          Sign in with an <br />
          SSO identity provider
        </div>
        <div className={css.subtitle}>and get ship done.</div>
        {multipleSSO.length > 0 ? (
          <div className={css.multipleIdpContainer}>
            <Text className={css.multipleIdpText}>
              Click on the IDP that you want to use to login to Harness
            </Text>
            <div className={css.ssoCardContainer}>
              {multipleSSO.map(renderSSOCard)}
            </div>
          </div>
        ) : (
          <Form
            onSubmit={handleSSOInit}
            render={({ handleSubmit }) => {
              return (
                <form
                  className="layout-vertical spacing-medium"
                  onSubmit={handleSubmit}
                >
                  <Field
                    name="email"
                    type="email"
                    label="Email"
                    placeholder="email@work.com"
                    disabled={loading}
                    validate={validateEmail}
                    autoFocus
                  />
                  <input
                    type="submit"
                    value="Sign in"
                    className="button primary"
                    disabled={loading}
                  />
                </form>
              );
            }}
          />
        )}
      </div>
    </BasicLayout>
  );
};

export default SSOSignIn;
